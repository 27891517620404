import Pagination from "@components/Pagination/Pagination"
import CustomSelect from "@components/elements/CustomSelect/CustomSelect"
import { Tab, Tabs } from "@components/elements/Tabs/Tabs"
import useDeviceMedia from "@hooks/useDeviceMedia"
import usePagination from "@hooks/usePagination"
import { capitalize, isEmpty } from "lodash"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./VideoListing.scss"
import { Link } from "gatsby"
import YoutubeIcon from "@components/icons/YoutubeIcon"
import { abbreviateNumber } from "@lib/utils"
import { useSubscriberStore } from "@lib/subscriberStore"
import { useSessionStorage } from "@hooks/useSessionStorage"
import VideoCard from "@components/NewsCard/VideoCard"
import useVideos from "@hooks/dataHooks/useVideos"
import AdsBanner from "@components/AdsBanner/AdsBanner"
import useVideosTrending from "@hooks/dataHooks/useVideosTrending"

const getYtData = async () => {
  try {
    const response = await fetch(
      `https://youtube.googleapis.com/youtube/v3/channels?part=statistics&id=UCtkxUTV18L4YsvIYfmRt_Lg&key=${process.env.GATSBY_YOUTUBE_API_KEY}`
    )
    if (!response.ok) {
      throw new Error("Something went wrong")
    }
    const data = await response.json()
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("🚀 NewsListing getYtData ~ error:", error)
    return null
  }
}

export const getCategories = (videos) => {
  return videos?.reduce(
    (acc, video) => {
      const videoCategories = video.category?.strapi_json_value
      if (!isEmpty(videoCategories)) {
        videoCategories.forEach((category) => {
          if (!acc.find((item) => item.value === category)) {
            acc.push({
              label: capitalize(category),
              value: category,
            })
          }
        })
      }
      return acc
    },
    [{ label: "All Categories", value: "all" }]
  )
}

const VideoListing = () => {
  const { isTablet } = useDeviceMedia()
  const [search, setSearch] = useState("")
  const allVideos = useVideos()
  const trending =  useVideosTrending()
  const [videosToShow, setvideosToShow] = React.useState(allVideos)

  const { subscribers, setSubscribers } = useSubscriberStore((state) => state)

  React.useEffect(() => {
    getYtData().then((data) => {
      const count = data?.items?.[0]?.statistics?.subscriberCount
      if (count) {
        setSubscribers(abbreviateNumber(count))
      }
    })
  }, [])

  const categories = React.useMemo(() => getCategories(allVideos), [allVideos])

  const [selectedCategory, setSelectedCategory] = React.useState(
    categories[0].value
  )

  const [currentPage, setCurrentPage] = useSessionStorage("video_page", 1)


  const paginationProps = usePagination({
    items: videosToShow,
    itemsPerPage: 16,
    customCurrentPage: currentPage,
    customSetCurrentPage: setCurrentPage,
  })

  // filter videos based on selected category
  React.useEffect(() => {
    if (selectedCategory === "all") {
      if (search) {
        let items = allVideos.filter((item, index) =>
          item?.title
            ?.toLowerCase()
            ?.includes(search.toLowerCase())
        )
        setvideosToShow(items)

      } else {
        setvideosToShow(allVideos)

      }
    } else {
      if (search) {
        let filteritems = allVideos.filter((video) => {
          const videoCategories = video.category?.strapi_json_value
          return videoCategories?.includes(selectedCategory)
        })

        let items = filteritems.filter((item, index) =>
          item?.title
            ?.toLowerCase()
            ?.includes(search.toLowerCase())
        )
        setvideosToShow(items)

      }
      else {
        setvideosToShow(
          allVideos.filter((video) => {
            const videoCategories = video.category?.strapi_json_value
            return videoCategories?.includes(selectedCategory)
          })
        )

      }

    }
    // paginationProps.setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, allVideos, search])
  // filter videos based on selected category
  const handleChange = event => {
    setSearch(event.target.value)
    paginationProps.setCurrentPage(1)
  }
  return (
    <div className="video-listing-wrap listing-wrap">
      <div className="category-section-wrap category-section">
        <Container className="category-section">
          <div className="max-filter">
            <div className="search-box-comm">
              <input
                className="form-control search"
                placeholder="Search by keyword"
                onChange={handleChange}
                value={search}
              />
              <span>
                <i className="icon grey-search-icon" />
              </span>
            </div>
            {/* {isTablet ? ( */}
            <CustomSelect
              options={categories}
              placeholder="Select Category"
              value={
                categories.find((item) => item.value === selectedCategory) ||
                categories[0]
              }
              onChange={(option) => {
                setSelectedCategory(option.value)
              }}
            />
          </div>
          {/* ) : (
            <div className="category-tabs-section">
              <Tabs
                activeKey={selectedCategory}
                setActiveKey={setSelectedCategory}
                className="category-tabs"
              >
                {categories.map((category) => (
                  <Tab
                    eventKey={category.value}
                    title={category.label}
                    key={category.value}
                  >
                    {category.label}
                  </Tab>
                ))}
              </Tabs>
            </div>
          )} */}
          {subscribers && (
            <div className="yt-sub-section">
              <Link
                to="https://www.youtube.com/@Providentestate"
                target="_blank"
                rel="noreferrer"
                className="button button-gray"
              >
                <span>{subscribers} Subscibers</span>
                <YoutubeIcon />
              </Link>
            </div>
          )}
        </Container>
      </div>
      <Container className="video-listing-container">
        <div className="video-category-select-section" />
        {!search && selectedCategory === "all" && currentPage === 1 &&
        <div className="video-listing-section big-layout">
          {trending?.map((video) => (
            <VideoCard key={video.id} news={video} noAnimation isbig />
          ))}
        </div>}
        {paginationProps?.currentItems?.length > 0 ? 
        <div className="video-listing-section">
          {paginationProps?.currentItems?.map((video, index) => (
            <React.Fragment key={video.id}>
              <VideoCard news={video} noAnimation />

              {/* Show AdsBanner after the 8th VideoCard (index 7) */}
              {index === 7 && (
                <AdsBanner
                  smallBanner
                  listView
                  isProperty
                  cardBanner={false}
                  noContainer
                  module={{ title: "Stay Updated with Provident" }}
                />
              )}
            </React.Fragment>
          )) 
          
          }
        </div>
: <p>Unfortunately, we do not currently have any Videos that match your search criteria.</p>}
        <Pagination {...paginationProps} />
      </Container>
    </div>
  )
}

export default VideoListing
