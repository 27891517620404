import { sort } from "@lib/utils"
import { graphql, useStaticQuery } from "gatsby"

const useVideosTrending = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiVideo(
        filter: {publish: {eq: true}, trending: {nin: ["", null]} }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          title
          video_url
          date(formatString: "MMMM DD, YYYY")
          trending
          category {
            strapi_json_value
            id
          }
          ggfx_results {
            ...STRAPI_GGFX_RESULTFragment
          }
          image {
            url
          }
        }
      }
    }
  `)

  const videos = data?.allStrapiVideo?.nodes || []

  // Define custom sorting order
  const order = { "one": 1, "two": 2 }

  // Sort videos: "one" first, "two" second, then others by date
  const sortedVideos = [...videos].sort((a, b) => {
    const rankA = order[a.trending] || 999
    const rankB = order[b.trending] || 999

    if (rankA !== rankB) {
      return rankA - rankB // Prioritize "one" and "two"
    }

    // If both are not "one" or "two", maintain existing date sorting
    // return new Date(b.date) - new Date(a.date)
  })

  return sortedVideos
}

export default useVideosTrending
