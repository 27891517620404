import React, { useState } from "react"
import "./NewsCard.scss"
import { Link } from "gatsby"
import Animation from "@components/elements/Animation"
import GGFXImage from "@components/elements/GGFXImage"
import { newsAndInsightURL } from "@lib/urls"
import getVideoId from "get-video-id"
import PlayVideoButtonFull from "@components/elements/PlayVideo/PlayVideoButtonFull"

const VideoCard = ({ news, index, noAnimation, isbig }) => {
  if (!news) return null

  const {
    title,
    image,
    category,
    date,
    ggfx_results,
    strapi_id,
    slug,
    video_url,
  } = news
  const [isOpen, setIsOpen] = useState(false)

  const videoId = getVideoId(video_url);
  const thumbnailUrl = videoId && isbig
  ? `https://img.youtube.com/vi/${videoId.id}/sddefault.jpg` : videoId ? `https://img.youtube.com/vi/${videoId.id}/mqdefault.jpg`
  : "";
  const categoryText = category?.strapi_json_value?.join(", ")
  return (
    <Animation
      animateInType="up"
      delay={index * 200}
      className="video-card"
      wrapperClassName="video-card-wrapper"
      noAnimation={noAnimation}
    >
      <div className="img-section-wrap img-zoom">
        <div 
        // to={`${newsAndInsightURL + slug}/`}
        onClick={() => setIsOpen(true)}
         className="img-section">
          {categoryText && <p className="img-tag">{categoryText}</p>}
          {thumbnailUrl ? (
        <img src={thumbnailUrl} alt="YouTube Thumbnail" width="300" />
      ):
          
          <GGFXImage
            ImageSrc={image}
            altText={title}
            imagetransforms={ggfx_results}
            renderer="srcSet"
            imagename={isbig ? "video.image.blog_big_card" : "video.image.blog_card"}
            strapiID={strapi_id}
          />}
        </div>
        {video_url && <PlayVideoButtonFull videoUrl={video_url} isOpen={isOpen} setIsOpen={setIsOpen} />}
      </div>
      <div 
      // to={`${newsAndInsightURL + slug}/`}
       className="title">
        {title}
      </div>
      <p className="date">{date}</p>
    </Animation>
  )
}

export default VideoCard
